import { styled } from '@mui/system';

import { INQUIRY_FORM_URL } from '@app/utils/constants';

const Root = styled('div')(({ theme }) => ({
  '& > a': {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
  color: 'gray',
  margin: 'auto',
  maxWidth: '26em',
  padding: '2em',
  textAlign: 'center',
}));

interface InquiryFormLinkProps {
  fontSize?: string;
}

export function InquiryFormLink({ fontSize = '14px' }: InquiryFormLinkProps) {
  return (
    <Root sx={{ fontSize }}>
      登録できない等、お困りのことがございましたら
      <a href={INQUIRY_FORM_URL} target="_blank" rel="noopener noreferrer">
        こちら
      </a>
      からお問い合わせください
    </Root>
  );
}
