import { MenuItem } from '@mui/material';

export function getMenuItems<T>(values: T[], key?: string) {
  return values?.map((item, idx) => {
    if (typeof item === 'string' || typeof item === 'number') {
      return (
        <MenuItem value={item} key={`${key ?? ''}-${idx}`}>
          {item || (typeof item === 'string' ? '　' : 0)}
        </MenuItem>
      );
    }

    const obj = item as {
      id?: string;
      label?: string;
      name?: string;
      title?: string;
      value?: string;
    };
    const value =
      obj.value ??
      obj.id ??
      obj.name ??
      obj.title ??
      obj.label ??
      obj.toString();
    const label =
      obj.label ??
      obj.title ??
      obj.name ??
      obj.id ??
      obj.value ??
      obj.toString();

    return (
      <MenuItem value={value} key={idx}>
        {label}
      </MenuItem>
    );
  });
}

export function convertNewLineToBr(
  str: string | undefined,
  separator: string | RegExp = /\\r\\n|\\r|\\n|\r\n|\n|\r/g
) {
  if (!str) {
    return '';
  }
  return str.split(separator).map((s, i) => [s, <br key={i} />]);
}
