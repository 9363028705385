import { ReviewStats } from '@app/types/review';
import { User } from '@app/types/user';

export const ORGANIZATION_STATUS = {
  FORBIDDEN: 'forbidden',
  NORMAL: 'normal',
  RESTRICTED: 'restricted',

  // MEMO
  // - 元のコード) https://github.com/libelize-com/epoc-kihoukai-supply-app/blob/d08d90a5d6e8128f34b0be55050fe11cee0f1d3e/src/types/organization.ts#L4-L6
  // - Document) https://www.notion.so/9-13-c2a35ac7b55e4001be376e84d92716cd
} as const;

export type SupportedOrganizationStatus =
  (typeof ORGANIZATION_STATUS)[keyof typeof ORGANIZATION_STATUS];

export const ORGANIZATION_STATUS_LABELS: {
  [key in SupportedOrganizationStatus]: string;
} = {
  [ORGANIZATION_STATUS.FORBIDDEN]: '禁止',
  [ORGANIZATION_STATUS.NORMAL]: '利用中',
  [ORGANIZATION_STATUS.RESTRICTED]: '制限付き',
};

export const OrganizationAuditStatus = {
  IN_REVIEW: 'in_review',
  WAITING_FOR_REVIEW: 'waiting_for_review',
} as const;

export type SupportedOrganizationAuditStatus =
  (typeof OrganizationAuditStatus)[keyof typeof OrganizationAuditStatus];

export interface Qualification {
  name: string;
  status: string;
  value: string;
}

export enum GenderSelf {
  Female = '女性',
  Male = '男性',
  NoAnswer = '無回答',
  None = '',
  Other = 'その他',
}

export interface Organization {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  auditStatus?: SupportedOrganizationAuditStatus;
  branchName?: string;
  certificateImageUri: string;
  chart?: boolean;
  createdAt?: string;
  customFields: Partial<CustomFields>; // TODO：正式に実装されたらPartial<>を取る
  description?: string;
  electronicChart?: boolean;
  electronicChartMaker?: string;
  equipment?: string[];
  equipmentOther?: string;
  genderSelf?: GenderSelf;
  iconImageUri: string;
  id: string;
  logoImageUri?: string;
  name: string;
  numberOfProjects?: number;
  ownerId?: string;
  phoneNumber: string;
  pictureUrl?: string;
  postalCode: string;
  reviewStats?: ReviewStats;
  size?: number;
  status?: SupportedOrganizationStatus;
}

export type OrganizationImageData = {
  certificateImageData: string;
  iconImageData: string;
  logoImageData: string;
};

type CustomFields = {
  access: string;
  addressByCity: string;
  addressLine4: string;
  chatContact?: string;
  clinicAppealMessage: string;
  clinicUrl: string;
  deleted_at?: string;
  department: string;
  electronicChartMaker: string;
  electronicCharts: string[];
  equipmentOther: string;
  equipments?: string[];
  mainImages: string;
  ownerName: string;
  ownerUserId: string;
  scoutCount?: number;
  scoutExpirationDate?: string;
  scoutMaxCount?: number;
};

export type OrganizationInput = Omit<
  Organization,
  'id' | 'certificateImageUri' | 'iconImageUri' | 'reviewStats'
> &
  OrganizationImageData & {
    customFields: CustomFields;
  };

export type ChangeRequestInput = Partial<
  Pick<
    Organization,
    | 'name'
    | 'branchName'
    | 'postalCode'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressLine3'
  > &
    OrganizationImageData
>;

export interface OrganizationPresidentInput {
  email: string;
  // userInfo: any;
  organization: OrganizationInput;
  password: string;
  permissions: string;
  phone: string;
  username: string;
}

export interface OrganizationMember {
  fullName: string;
  id: string;
  mailAddress: string;
  permission: 'owner' | 'member';
  phoneNumber: string;
  status: 'registered' | 'invited';
}

export interface OrganizationMemberLocalized extends OrganizationMember {
  permissionLocalized: string;
  statusLocalized: string;
}

export interface OrganizationFormData {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  branchName?: string;
  certificateImageUri?: string;
  customFields: {
    access: string;
    addressLine4: string;
    clinicAppealMessage: string;
    clinicUrl: string;
    department: string;
    electronicChartMaker: string;
    electronicCharts: string;
    equipmentOther: string;
    equipments: string[];
    mainImages: string;
    ownerName: string;
    ownerUserId: string;
  };
  description?: string;
  hasCertificate?: string;
  name?: string;
  notificationNumber?: string;
  numberOfProjects?: string;
  phoneNumber?: string;
  pictureUrl?: string;
  postalCode?: {
    city: string;
    prefecture: string;
  };
  size?: string;
}

export interface OrganizationUpdateInput {
  address?: string;
  branchName?: string;
  customFields?: Partial<CustomFields>;
  description?: string;
  iconImageData?: string;
  name?: string;
  numberOfProjects?: number;
  phoneNumber?: string;
  pictureUrl?: string;
  postalCode?: string;
  size?: number;
  status?: SupportedOrganizationStatus;
}

export type OrganizationMemberInput = Omit<OrganizationMember, 'id'>;

export const OrganizationType = {
  COMPANY: '010',
  PERSONAL: '011',
} as const;

export type SupportedOrganizationType =
  (typeof OrganizationType)[keyof typeof OrganizationType];

export const OrganizationUserRole = {
  ADMIN: 'admin',
  GUEST: 'guest',
  MEMBER: 'member',
  OWNER: 'owner',
} as const;

export type SupportedOrganizationUserRole =
  (typeof OrganizationUserRole)[keyof typeof OrganizationUserRole];

export interface OrganizationUser extends Partial<User> {
  role: SupportedOrganizationUserRole;
}
