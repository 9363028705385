import {
  AccountCircle as AccountCircleIcon,
  SaveAltOutlined as SaveAltOutlinedIcon,
} from '@mui/icons-material';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { ReactElement, useMemo } from 'react';

import { MessageTypeFile } from '@app/components/Chat/MessageTypeFile';
import { Message, MessageCategory, MessageTypeId } from '@app/types/chats';
import { jsonParse } from '@app/utils/format';

type Props = {
  message: Message;
  onContextMenu?: (
    event: React.MouseEvent | React.TouchEvent,
    messageId: string
  ) => void;
  onTouchEnd?: (event: React.TouchEvent) => void;
  onTouchStart?: (event: React.TouchEvent, messageId: string) => void;
};

export function MessageItem({
  message,
  onContextMenu,
  onTouchEnd,
  onTouchStart,
}: Props): ReactElement {
  const theme = useTheme();

  const isDeleted = useMemo(() => {
    return message.content === 'メッセージは削除されました';
  }, [message.content]);
  const isSent = useMemo(() => {
    return message.category === MessageCategory.SUPPLY;
  }, [message.category]);
  const isReceived = useMemo(() => {
    return message.category === MessageCategory.DEMAND;
  }, [message.category]);
  const isTypeFile = useMemo(() => {
    return (
      message.typeId === MessageTypeId.IMAGE ||
      message.typeId === MessageTypeId.FILE
    );
  }, [message.typeId]);

  const handleClickDownload = async (url: string, name: string) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <Stack
      spacing={1}
      direction="row"
      width={1}
      alignItems="flex-end"
      justifyContent={isSent ? 'flex-end' : 'flex-start'}
    >
      {isReceived && (
        <AccountCircleIcon
          fontSize="large"
          sx={{
            bottom: '-4px',
            color: 'lightblue',
            position: 'relative',
          }}
        />
      )}
      <Stack
        direction={isSent ? 'row' : 'row-reverse'}
        spacing={1}
        alignItems="flex-end"
      >
        <Stack>
          {isTypeFile && (
            <Stack direction="row">
              <IconButton
                onClick={() =>
                  handleClickDownload(
                    message.content,
                    jsonParse(message.title)?.name
                  )
                }
              >
                <SaveAltOutlinedIcon fontSize="small" />
              </IconButton>
            </Stack>
          )}
          <Typography
            variant="body2"
            component="div"
            sx={{
              color: theme.customPalette.gray,
              textAlign: isSent ? 'right' : 'left',
            }}
          >
            {isReceived && (
              <Box>{`${message.sender.customFields.familyName}${message.sender.customFields.firstName}`}</Box>
            )}
            <Box>{format(new Date(message.createdAt), 'HH:mm')}</Box>
          </Typography>
        </Stack>
        <Grid
          onContextMenu={(e) => {
            if (isSent && !isDeleted) {
              onContextMenu && onContextMenu(e, message.id);
            }
          }}
          onTouchStart={(e) => {
            if (isSent && !isDeleted) {
              onTouchStart && onTouchStart(e, message.id);
            }
          }}
          onTouchEnd={onTouchEnd}
        >
          {isDeleted ? (
            <Box
              sx={{
                backgroundColor: theme.customPalette.white,
                border: `1px solid ${theme.customPalette.silver}`,
                borderRadius: isSent
                  ? '20px 20px 0px 20px'
                  : '20px 20px 20px 0px',
                color: theme.customPalette.gray,
                padding: '10px 16px',
              }}
            >
              <Typography>{message.content}</Typography>
            </Box>
          ) : message.typeId === MessageTypeId.MESSAGE ||
            message.typeId === MessageTypeId.CHANNEL ? (
            <Box
              sx={{
                backgroundColor: isSent
                  ? theme.palette.primary.main
                  : theme.customPalette.silver,
                borderRadius: isSent
                  ? '20px 20px 0px 20px'
                  : '20px 20px 20px 0px',
                color: isSent
                  ? `${theme.customPalette.white}`
                  : `${theme.palette.text}`,
                padding: '10px 16px',
              }}
            >
              <Typography
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  __html: message.content
                    .replace(/\\n/g, '\n')
                    .replace(/\n/g, '<br />'),
                }}
              />
            </Box>
          ) : (
            <Stack direction={isSent ? 'row' : 'row-reverse'}>
              {message.typeId === MessageTypeId.IMAGE ? (
                <img
                  src={message.content}
                  alt={jsonParse(message.title)?.name || '無題'}
                  style={{
                    borderRadius: '10px',
                    maxWidth: '230px',
                  }}
                />
              ) : (
                message.typeId === MessageTypeId.FILE && (
                  <MessageTypeFile
                    name={jsonParse(message.title)?.name || '無題'}
                    size={jsonParse(message.title)?.size || 0}
                  />
                )
              )}
            </Stack>
          )}
        </Grid>
      </Stack>
    </Stack>
  );
}
