import { STORAGE_KEYS } from '@app/utils/constants';

const ACCESS_TOKEN_KEY = STORAGE_KEYS.ACCESS_TOKEN;
const REFRESH_TOKEN_KEY = STORAGE_KEYS.REFRESH_TOKEN;
const FINGERPRINT_KEY = STORAGE_KEYS.FINGERPRINT;
const USER_ID_KEY = STORAGE_KEYS.USER_ID;

const { localStorage } = globalThis;

export function formatBearerToken(token: string) {
  return `Bearer ${token}`;
}

export const getStoredFingerprint = () => localStorage.getItem(FINGERPRINT_KEY);

export function storeFingerprint(fingerprint: string) {
  localStorage.setItem(FINGERPRINT_KEY, fingerprint);
}

export function removeStoredFingerprint() {
  localStorage.removeItem(FINGERPRINT_KEY);
}

export function getStoredUserId() {
  return localStorage.getItem(USER_ID_KEY);
}

export function storeUserId(userId: string) {
  localStorage.setItem(USER_ID_KEY, userId);
}

export function removeStoredUserId() {
  localStorage.removeItem(USER_ID_KEY);
}

//
// AccessToken
//
export function getStoredAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function storeAccessToken(token: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function removeStoredAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

//
// RefreshToken
//

export function getStoredRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function storeRefreshToken(token: string) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

export function removeStoredRefreshToken() {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}
