import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tantySubText: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    tantySubText?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tantySubText: true;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    customPalette: {
      alert: string;
      borderColor: string;
      calendarToday: string;
      gray: string;
      lightGray: string;
      selection: string;
      silver: string;
      white: string;
      yellow: string;
    };
  }
  interface ThemeOptions {
    customPalette?: {
      alert: string;
      black: string;
      borderColor: string;
      calendarToday: string;
      gray: string;
      lightGray: string;
      selection: string;
      silver: string;
      white?: string;
      yellow: string;
    };
  }
}

export const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.form-control-gray': {
            background: '#F4F4F6',
            borderColor: '#000 !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedSecondary: {
          backgroundColor: '#fff',
          borderColor: '#DBDFE5',
          color: '#2F2F2F',
          fontWeight: 300,
        },
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#8890A066',
            color: '#fff',
          },
          '&.MuiButton-outlinedError': {
            color: 'rgba(238, 99, 99, 1)',
          },
          boxShadow: 'none !important',
          color: '#fff',
          fontSize: '16px',
          fontWeight: 600,
          minWidth: 40,
        },
        sizeMedium: {
          height: '52px',
        },
        sizeSmall: {
          fontWeight: 'normal',
          height: '40px',
        },
      },
    },
    MuiCheckbox: {
      variants: [
        {
          props: { className: 'required' },
          style: {
            '&.Mui-checked': {
              color: '#ffc5c5',
            },
            color: '#ffc5c5',
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // Default breakpoints: https://mui.com/material-ui/customization/breakpoints/#default-breakpoints
          // xs
          // eslint-disable-next-line no-useless-computed-key
          ['@media (min-width: 0px)']: {
            backgroundColor: '#fff',
          },
          // md
          // eslint-disable-next-line no-useless-computed-key
          ['@media (min-width: 900px)']: {
            backgroundColor: '#f8f8f9',
          },
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.MuiFormControl-fullWidth': {
            '&.form-control-gray': {
              background: '#F4F4F6',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:visited': {
            color: 'inherit',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            ':hover': {
              backgroundColor: '#19A7CE',
              color: '#fff',
            },
            backgroundColor: '#19A7CE',
            color: '#fff',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#d1d1d1 !important',
            },
            background: '#F4F4F6',
          },
          input: {
            '&.Mui-disabled': {
              WebkitTextFillColor: '#2F2F2F !important',
              background: '#F4F4F6',
              // paddingBottom: '7px',
              // paddingTop: '0',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 2px rgba(47, 87, 166, 0.2)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            background: '#F4F4F6',
          },
        },
      },
      variants: [
        {
          props: { required: true },
          style: {
            backgroundColor: '#FFE9E9',
          },
        },
      ],
    },

    MuiTableCell: {
      styleOverrides: {
        body: {
          borderColor: 'rgba(176, 183, 195, 0.4)',
          borderWidth: '0.5px',
          fontSize: '1rem',
          paddingBottom: '1.65rem',
          paddingTop: '1.65rem',
        },
        head: {
          color: '#2F2F2F',
          fontSize: '0.75rem !important',
          fontWeight: 600,
          paddingBottom: '0.75rem !important',
          paddingTop: '0.75rem !important',
          textAlign: 'center',
        },
        sizeMedium: {
          paddingBottom: '1.65rem',
          paddingTop: '1.65rem',
        },
        sizeSmall: {
          paddingBottom: '0.75rem',
          paddingTop: '0.75rem',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(136, 144, 160, 0.2)',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          fontSize: '1rem',
        },
        select: {
          border: '1px solid rgba(176, 183, 195, 1)',
          borderRadius: '4px',
          fontSize: '1rem',
          textAlignLast: 'left',
          width: '3rem',
        },
        selectLabel: {
          fontSize: '1rem',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: 'rgba(106, 70, 209, 0.1)',
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px #FFE9E9 inset',
            boxShadow: '0 0 0 30px #FFE9E9 inset',
            transition: 'background-color 5000s ease-in-out 0s',
          },
          '&.required .MuiInputBase-multiline': {
            backgroundColor: '#FFE9E9',
          },
          '&.required .MuiSelect-select': {
            backgroundColor: '#FFE9E9',
          },
          '&.required input': {
            backgroundColor: '#FFE9E9',
          },
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: 600,
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  customPalette: {
    alert: '#F97474',
    black: '#2F2F2F',
    borderColor: '#DBDFE5',
    calendarToday: '#E4F7FC',
    gray: '#5A6170',
    lightGray: '#AEAEAE',
    selection: '#F7FBFB',
    silver: '#E8E9EC',
    white: '#ffffff',
    yellow: '#F4D116',
  },
  palette: {
    error: {
      main: '#EE6363',
    },
    primary: {
      main: '#19A7CE',
    },
    secondary: {
      main: 'rgba(136, 144, 160, 0.4)',
    },
    text: {
      primary: '#2f2f2f',
    },
  },
  typography: {
    fontFamily: [
      '"Segoe UI"',
      '"Meiryo"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Hiragino Sans"',
      '"Roboto"',
      '"Noto Sans JP"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    tantySubText: {
      color: '#5a6170',
      fontSize: '0.875rem',
    },
  },
});
