export type FileUrlSetter = (url: string) => void;

/**
 * factory of handler to set image url
 * @param fileUrlSetter
 * @returns handler to set image url
 */
export function handleFileFactory(fileUrlSetter: FileUrlSetter) {
  return (file: File | null) => {
    if (file) {
      const objectUrl = file ? URL.createObjectURL(file) : '';
      fileUrlSetter(objectUrl);
    }
  };
}

/**
 * factory of handler to delete image url
 * @param fileUrlSetter
 * @returns handler to delete image url
 */
export function handleDeleteFactory(fileUrlSetter: FileUrlSetter) {
  return (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    fileUrlSetter('');
  };
}
