import { Grid, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <Grid
      container
      flexDirection="column"
      alignContent="center"
      justifyContent="center"
    >
      <Typography variant="h1" style={{ textAlign: 'center', width: '100%' }}>
        PAGE NOT FOUND
      </Typography>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <Link to="/">
          <Button variant="contained" color="error">
            BACK
          </Button>
        </Link>
      </div>
    </Grid>
  );
};
