import { Typography, Grid, Card, CardContent } from '@mui/material';

export function WaitingReset() {
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              sx={{
                mb: 10,
                minWidth: '450px',
                pb: 5,
                pt: 5,
                textAlign: 'center',
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    fontSize: 24,
                    fontWeight: 600,
                    lineHeight: '33.6px',
                    mb: 4,
                  }}
                >
                  パスワード再設定手続きの
                  <br />
                  メールをお送りしました
                </Typography>

                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={10} container textAlign={'left'}>
                    <Typography sx={{ fontWeight: 300 }}>
                      ご登録メールアドレス宛にパスワード再発行手続きのメールをお送りしました。
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 300, pt: 3 }}
                    >
                      メール本文に記述されているURLをクリックして頂き、パスワード再設定手続きを行ってください。
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
