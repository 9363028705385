import { TextField, TextFieldProps } from '@mui/material';
import { format as dateFnsFormat, parse as dateFnsParse } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { forwardRef, ForwardedRef } from 'react';

export interface DateFieldProps
  extends Omit<TextFieldProps, 'value' | 'onChange'> {
  /** フォーマット  date-fnsのフォーマット形式に基づく*/
  format?: string;
  onChange?: (
    value: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  /** Date型が認識できる日付文字列 */
  value?: string;
}

// MEMO:ReactHookFormのControllerで使用する場合forwardRefでrefを渡せるようにしないとWarningとなる。
export const DateField = forwardRef(function (
  { value, onChange, format, ...textFieldProps }: DateFieldProps,
  ref: ForwardedRef<unknown>
) {
  const valueDate =
    value !== undefined
      ? format
        ? dateFnsParse(value, format, new Date(), { locale: ja })
        : new Date(value ?? '-')
      : undefined;
  const innerValue =
    valueDate !== undefined
      ? !isNaN(valueDate?.getTime())
        ? dateFnsFormat(valueDate, 'yyyy-MM-dd')
        : ''
      : undefined;

  return (
    <TextField
      {...textFieldProps}
      ref={ref as ForwardedRef<HTMLDivElement>}
      type="date"
      value={innerValue}
      onChange={(e) => {
        if (!format) {
          onChange?.(e.target.value, e);
          return;
        }
        const date = new Date(e.target.value);
        if (isNaN(date.getTime())) {
          onChange?.('', e);
          return;
        }
        onChange?.(dateFnsFormat(date, format, { locale: ja }), e);
        return;
      }}
    />
  );
});
