import { Typography } from '@mui/material';

interface SubPageTitleProps {
  title: string;
}
export function SubPageTitle({ title }: SubPageTitleProps) {
  return (
    <Typography
      variant="h5"
      component="div"
      sx={{
        color: '#2F2F2F',
        fontFamily: 'Hiragino Kaku Gothic Pro',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%',
      }}
    >
      {title}
    </Typography>
  );
}
