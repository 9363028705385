/*
 * 一般ユーザとシステム管理ユーザでコンポーネント共有する際、
 * navigate する URL を rewrite する
 */
import { useNavigate, NavigateOptions, To } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { isAdministrator } from '@app/administrator/utils/is_administrator';
import { loggedInUserState } from '@app/domain/app';
import { organization as organizationAtom } from '@app/domain/organization';

export function useNavigateAdminDelegatable() {
  const navigate = useNavigate();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const organization = useRecoilValue(organizationAtom);

  const isAdmin = loggedInUser && isAdministrator(loggedInUser.typeId);

  return (originalTo: To, options?: NavigateOptions) => {
    // TODO: Production build / Dev環境にて期待動作していないのでLog出力
    console.debug('[useNavigateAdminDelegatable] START', {
      loggedInUser,
      options,
      organization,
      originalTo,
    });
    if (
      isAdmin &&
      organization.id.length > 0 &&
      typeof originalTo === 'string'
    ) {
      const nextTo = `/org/${organization.id}${originalTo}`;
      console.debug('[useNavigateAdminDelegatable] NEXT', { nextTo });
      navigate(nextTo, options);
      return;
    }
    // 対象外は何もしない
    navigate(originalTo, options);
  };
}
