import { TextField, TextFieldProps } from '@mui/material';
import { useState, forwardRef, ForwardedRef, useEffect } from 'react';

export interface NumberFieldProps
  extends Omit<TextFieldProps, 'value' | 'onChange'> {
  isDecimal?: boolean;
  onChange?: (
    value: number | null,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  value?: number | null;
}

// MEMO:ReactHookFormのControllerで使用する場合forwardRefでrefを渡せるようにしないとWarningとなる。
export const NumberField = forwardRef(function (
  {
    value,
    onChange,
    onFocus,
    onBlur,
    isDecimal,
    ...textFieldProps
  }: NumberFieldProps,
  ref
) {
  const [innerValue, setInnerValue] = useState(value?.toString() ?? '');

  useEffect(() => {
    const half = toHalf(innerValue);
    if (value === undefined && (!innerValue || half === '-')) {
      return;
    }
    const num = Number(half.replaceAll(',', '') || 'NaN');
    if (num !== value) {
      setInnerValue(value?.toString() ?? '');
    }
  }, [value, innerValue, setInnerValue]);

  return (
    <TextField
      {...textFieldProps}
      ref={ref as ForwardedRef<HTMLDivElement>}
      inputProps={{
        ...textFieldProps.inputProps,
        sx: [
          { textAlign: 'right' },
          // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
          ...(textFieldProps.inputProps?.sx
            ? Array.isArray(textFieldProps.inputProps.sx)
              ? textFieldProps.inputProps.sx
              : [textFieldProps.inputProps.sx]
            : []),
        ],
      }}
      value={innerValue}
      onChange={(e) => {
        const half = toHalf(e.target.value);
        if (!e.target.value || half === '-') {
          setInnerValue(e.target.value);
          onChange?.(null, e);
          return;
        }
        const num = Number(half.replaceAll(',', '') || 'NaN');
        const selectionStart = e.target.selectionStart;
        if (isNaN(num) || (!isDecimal && !Number.isInteger(num))) {
          e.target.value = innerValue ?? '';
          e.target.selectionStart = (selectionStart ?? 0) - 1;
          e.target.selectionEnd = e.target.selectionStart;
        }
        setInnerValue(e.target.value);
        onChange?.(
          e.target.value
            ? Number(toHalf(e.target.value).replaceAll(',', ''))
            : null,
          e
        );
      }}
      onFocus={(e) => {
        const num = Number(innerValue.replaceAll(',', ''));
        if (innerValue && !isNaN(num)) {
          setInnerValue(num.toString());
        }
        onFocus?.(e);
      }}
      onBlur={(e) => {
        const num = Number(toHalf(innerValue).replaceAll(',', ''));
        if (innerValue && !isNaN(num)) {
          setInnerValue(num.toLocaleString());
        }
        onBlur?.(e);
      }}
    />
  );
});

function toHalf(str: string) {
  return str
    .replaceAll('，', ',')
    .replaceAll(/[－ー]/g, '-')
    .replaceAll('．', '.')
    .replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
}
