import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { Controller, useForm, FieldErrors } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { ProgressStepper } from '@app/components/Registration/Confirmation/ProgressStepper';
import { userState } from '@app/domain/register';
import {
  UseInfoForm,
  UserInfoFormData,
  GenderSelf,
  Gender,
} from '@app/schemas/registration';
import { dayOptions, monthOptions, yearOptions } from '@app/utils/constants';

interface OrganizationFormProps {
  onSubmit: (formData: UserInfoFormData) => void;
}

/**
 * blocks-5f90
 *
 * A form to let new vendors put their information.
 * After they submit the information, tanty admins will give them checkups.
 */
export function UserInfoForm({
  onSubmit,
}: OrganizationFormProps): ReactElement {
  const [userData, setUserData] = useRecoilState(userState);
  const { control, handleSubmit, formState } = useForm<UserInfoFormData>({
    defaultValues: userData,
    mode: 'onChange',
    resolver: UseInfoForm.resolver,
  });

  const { isValid } = formState;
  const [isDateChanged, setDateChanged] = useState(false);

  const onSubmitValid = useCallback(
    (data: UserInfoFormData) => {
      setUserData((prev) => ({ ...prev, ...data }));
      onSubmit(data);
    },
    [onSubmit, setUserData]
  );
  const onSubmitInvalid = useCallback(
    (errors: FieldErrors<UserInfoFormData>) => {
      console.debug('[UserInfoFrom] onSubmitInvalid()', errors);
    },
    []
  );

  return (
    <Box>
      <ProgressStepper activeStep={0} />
      <Typography
        variant="h6"
        fontWeight={700}
        textAlign="center"
        sx={{ mb: 5 }}
      >
        ユーザー情報
      </Typography>
      <Typography variant="body2" textAlign="left" gutterBottom sx={{ mb: 5 }}>
        主に使用する担当者様の情報をご記入ください
        <br />
        <Typography component="span" color="error">
          *
        </Typography>
        は必須事項です
      </Typography>

      <form onSubmit={handleSubmit(onSubmitValid, onSubmitInvalid)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                >
                  姓
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.familyName"
                control={control}
                defaultValue={userData.customFields.familyName}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    value={userData.customFields.familyName}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        customFields: {
                          ...userData.customFields,
                          familyName: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'姓を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                >
                  名
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.firstName"
                control={control}
                defaultValue={userData.customFields.firstName}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    value={userData.customFields.firstName}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        customFields: {
                          ...userData.customFields,
                          firstName: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'名を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                >
                  姓(かな)
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.familyNameKana"
                control={control}
                defaultValue={userData.customFields.familyNameKana}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    value={userData.customFields.familyNameKana}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        customFields: {
                          ...userData.customFields,
                          familyNameKana: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'姓(せい)を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                >
                  名(かな)
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.firstNameKana"
                control={control}
                defaultValue={userData.customFields.firstNameKana}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    value={userData.customFields.firstNameKana}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        customFields: {
                          ...userData.customFields,
                          firstNameKana: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'名(めい)を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                  >
                    性別
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography>
                </FormLabel>
                <Controller
                  name="customFields.gender"
                  control={control}
                  defaultValue={userData.customFields.gender}
                  rules={{ required: '性別を選択してください' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="outlined" error={!!error}>
                      <Select
                        {...field}
                        required
                        value={userData.customFields.gender}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            customFields: {
                              ...userData.customFields,
                              gender: e.target.value as Gender,
                            },
                          });
                          field.onChange(e);
                        }}
                        margin="dense"
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected) {
                            return selected;
                          }
                          return (
                            <span style={{ color: '#AEAEAE' }}>性別を選択</span>
                          );
                        }}
                      >
                        <MenuItem value="" disabled>
                          性別を選択
                        </MenuItem>
                        <MenuItem value="女性">女性</MenuItem>
                        <MenuItem value="男性">男性</MenuItem>
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ pt: 3 }}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                  >
                    自認の性別
                  </Typography>
                </FormLabel>
                <Controller
                  name="customFields.genderSelf"
                  control={control}
                  defaultValue={userData.customFields.genderSelf}
                  render={({ field }) => (
                    <FormControl variant="outlined">
                      <Select
                        {...field}
                        value={userData.customFields.genderSelf}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            customFields: {
                              ...userData.customFields,
                              genderSelf: e.target.value as GenderSelf,
                            },
                          });
                          field.onChange(e);
                        }}
                        margin="dense"
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected) {
                            return selected;
                          }
                          return (
                            <span style={{ color: '#AEAEAE' }}>性別を選択</span>
                          );
                        }}
                      >
                        <MenuItem value="" disabled>
                          性別を選択
                        </MenuItem>
                        <MenuItem value={GenderSelf.Female}>女性</MenuItem>
                        <MenuItem value={GenderSelf.Male}>男性</MenuItem>
                        <MenuItem value={GenderSelf.Other}>その他</MenuItem>
                        <MenuItem value={GenderSelf.NoAnswer}>無回答</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ ml: 3, pt: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                  >
                    生年月日
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography>
                </FormLabel>
                <Grid sx={{ alignItems: 'center', display: 'flex' }}>
                  <Controller
                    name="customFields.birthday"
                    control={control}
                    rules={{ required: '生年月日を入力してください' }}
                    render={({ field }) => (
                      <FormControl variant="outlined">
                        <Select
                          {...field}
                          required
                          value={
                            userData.customFields.birthday.split('/')[0] ||
                            '1980'
                          }
                          onChange={(e) => {
                            const birthDateParts =
                              userData.customFields.birthday.split('/');
                            birthDateParts[0] = e.target.value;
                            const birthDateValue = birthDateParts.join('/');
                            setUserData({
                              ...userData,
                              customFields: {
                                ...userData.customFields,
                                birthday: birthDateValue,
                              },
                            });
                            field.onChange(birthDateValue);
                            setDateChanged(true);
                          }}
                          margin="dense"
                          displayEmpty
                          renderValue={(selected) => {
                            if (
                              selected &&
                              (isDateChanged || selected !== '1980')
                            ) {
                              return selected;
                            }
                            return (
                              <span style={{ color: '#AEAEAE' }}>1980</span>
                            );
                          }}
                        >
                          {yearOptions.map((year) => (
                            <MenuItem value={year} key={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ pl: 0.7 }}
                  >
                    年
                  </Typography>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={2.5} sx={{ mt: 3 }}>
              <FormControl fullWidth variant="outlined">
                <Grid sx={{ alignItems: 'center', display: 'flex' }}>
                  <Controller
                    name="customFields.birthday"
                    control={control}
                    rules={{ required: '生年月日を入力してください' }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl variant="outlined" error={!!error}>
                        <Select
                          {...field}
                          required
                          value={
                            userData.customFields.birthday.split('/')[1] || '1'
                          }
                          onChange={(e) => {
                            const birthDateParts =
                              userData.customFields.birthday.split('/');
                            birthDateParts[1] = e.target.value;
                            const birthDateValue = birthDateParts.join('/');
                            setUserData({
                              ...userData,
                              customFields: {
                                ...userData.customFields,
                                birthday: birthDateValue,
                              },
                            });
                            field.onChange(birthDateValue);
                            setDateChanged(true);
                          }}
                          margin="dense"
                          displayEmpty
                          renderValue={(selected) => {
                            if (
                              selected &&
                              (isDateChanged || selected !== '1')
                            ) {
                              return selected;
                            }
                            return <span style={{ color: '#AEAEAE' }}>1</span>;
                          }}
                        >
                          {monthOptions.map((month) => (
                            <MenuItem value={month} key={month}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ pl: 0.7 }}
                  >
                    月
                  </Typography>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={2.5} sx={{ mt: 3 }}>
              <FormControl fullWidth variant="outlined">
                <Grid sx={{ alignItems: 'center', display: 'flex' }}>
                  <Controller
                    name="customFields.birthday"
                    control={control}
                    rules={{ required: '生年月日を入力してください' }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl variant="outlined" error={!!error}>
                        <Select
                          {...field}
                          required
                          value={
                            userData.customFields.birthday.split('/')[2] || '1'
                          }
                          onChange={(e) => {
                            const birthDateParts =
                              userData.customFields.birthday.split('/');
                            birthDateParts[2] = e.target.value;
                            const birthDateValue = birthDateParts.join('/');
                            setUserData({
                              ...userData,
                              customFields: {
                                ...userData.customFields,
                                birthday: birthDateValue,
                              },
                            });
                            field.onChange(birthDateValue);
                            setDateChanged(true);
                          }}
                          margin="dense"
                          displayEmpty
                          renderValue={(selected) => {
                            if (
                              selected &&
                              (isDateChanged || selected !== '1')
                            ) {
                              return selected;
                            }
                            return <span style={{ color: '#AEAEAE' }}>1</span>;
                          }}
                        >
                          {dayOptions.map((day) => (
                            <MenuItem value={day} key={day}>
                              {day}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ pl: 0.7 }}
                  >
                    日
                  </Typography>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="customFields.birthday"
                control={control}
                rules={{ required: '生年月日を入力してください' }}
                render={({ field, fieldState: { error } }) => (
                  <Typography color="error" variant="subtitle2">
                    {error && error.message}
                  </Typography>
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <Grid item xs={12} sx={{ pt: 6, px: 3 }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {}}
                disabled={!isValid}
              >
                保存して次へ
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
