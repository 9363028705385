import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import {
  Select,
  SelectProps,
  MenuItem,
  useTheme,
  Box,
  Button,
  BoxProps,
  styled,
} from '@mui/material';
import { addMonths, parse, startOfMonth, startOfYear } from 'date-fns';

const ChangeButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `solid 1px ${theme.palette.grey[400]}`,
  maxHeight: '2rem',
  maxWidth: '2rem',
  minHeight: '2rem',
  minWidth: '2rem',
}));

export interface MonthSelectProps extends Omit<BoxProps, 'onChange'> {
  /** 戻る機能を有効にするか */
  isBack?: boolean;
  /** 空白選択を入れるかどうか */
  isEmpty?: boolean;
  /** 進む機能を有効にするか */
  isForward?: boolean;
  /** 選択内容の月数 */
  monthCount?: number;
  /** 月選択のプロパティ */
  monthProps?: SelectProps<string>;
  /**
   * 値変更時のイベント
   * @param value "YYYY/MM"形式、未選択なら空白
   * @returns
   */
  onChange?: (value: string) => void | Promise<void>;
  /** 選択内容の開始月（"YYYY/MM"形式） */
  startMonth?: string;
  /** 値（"YYYY/MM"形式、未選択なら空白） */
  value?: string;
  /** 年選択のプロパティ */
  yearProps?: SelectProps<string>;
}

/**
 * 月選択コンポーネント
 * @param props
 * @returns
 */
export function MonthSelect({
  onChange,
  value,
  startMonth,
  monthCount: itemCount,
  isEmpty,
  yearProps,
  monthProps,
  isBack,
  isForward,
  ...boxProps
}: MonthSelectProps) {
  const startMonthDate = startMonth
    ? startOfMonth(parse(startMonth, 'yyyy/M', new Date()))
    : startOfYear(new Date());
  const yearMonthList = Array.from(Array(itemCount ?? 12 * 3).keys()).map(
    (item) => {
      const ym = addMonths(startMonthDate, item);
      const month = (ym.getMonth() + 1).toString();
      const year = ym.getFullYear().toString();
      return {
        month,
        value: `${year}/${month}`,
        year,
      };
    }
  );

  const yearMonth = value
    ? startOfMonth(parse(value, 'yyyy/M', new Date()))
    : undefined;

  const year = yearMonth
    ? yearMonthList.some((ym) => ym.year === yearMonth.getFullYear().toString())
      ? yearMonth.getFullYear().toString()
      : ''
    : '';
  const month = yearMonth
    ? yearMonthList.some(
        (ym) =>
          ym.year === year && ym.month === (yearMonth.getMonth() + 1).toString()
      )
      ? (yearMonth.getMonth() + 1).toString()
      : ''
    : '';

  const yearList = Array.from(new Set(yearMonthList.map((ym) => ym.year)));
  const monthList = yearMonthList
    .filter((ym) => ym.year === year)
    .map((ym) => ym.month);

  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={0.5}
      {...boxProps}
    >
      {isBack && (
        <ChangeButton
          variant="outlined"
          disabled={
            yearMonthList[0]?.year === year && yearMonthList[0]?.month === month
          }
          onClick={async () => {
            const yearMonth = yearMonthList
              .reverse()
              .find(
                (ym) =>
                  ym.year.padStart(4, '0') < year.padStart(4, '0') ||
                  (ym.year === year &&
                    ym.month.padStart(2, '0') < month.padStart(2, '0'))
              );
            if (!yearMonth) {
              return;
            }
            await onChange?.(yearMonth.value);
          }}
        >
          <ArrowBackIosNew fontSize="small" color="action" />
        </ChangeButton>
      )}
      <Select
        size="small"
        fullWidth
        {...yearProps}
        sx={[
          {
            backgroundColor: theme.palette.background.default,
            width: '6.5rem',
          },
          // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
          ...(yearProps?.sx
            ? Array.isArray(yearProps.sx)
              ? yearProps.sx
              : [yearProps.sx]
            : []),
        ]}
        value={year}
        onChange={async (e) => {
          const yearMonth =
            yearMonthList.find(
              (ym) => ym.year === e.target.value && ym.month === month
            ) ?? yearMonthList.find((ym) => ym.year === e.target.value);
          if (!yearMonth) {
            await onChange?.('');
            return;
          }
          await onChange?.(yearMonth.value);
        }}
        displayEmpty
        renderValue={(value) => {
          if (value) {
            return `${value}年`;
          }
          return (
            <span
              style={{
                color: theme.customPalette.lightGray,
              }}
            >
              年
            </span>
          );
        }}
      >
        {(isEmpty || !year) && <MenuItem value={''}>{'　'}</MenuItem>}
        {yearList.map((year) => (
          <MenuItem value={year} key={year}>
            {year}年
          </MenuItem>
        ))}
      </Select>
      <Select
        size="small"
        fullWidth
        {...monthProps}
        sx={[
          {
            backgroundColor: theme.palette.background.default,
            width: '5rem',
          },
          // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
          ...(monthProps
            ? Array.isArray(monthProps.sx)
              ? monthProps.sx
              : [monthProps.sx]
            : []),
        ]}
        value={month ?? month}
        onChange={async (e) => {
          const yearMonth = yearMonthList.find(
            (ym) => ym.year === year && ym.month === e.target.value
          );
          if (!yearMonth) {
            await onChange?.('');
            return;
          }
          await onChange?.(yearMonth.value);
        }}
        displayEmpty
        renderValue={(value) => {
          if (value) {
            return `${value}月`;
          }
          return (
            <span
              style={{
                color: theme.customPalette.lightGray,
              }}
            >
              月
            </span>
          );
        }}
      >
        {(isEmpty || !month) && <MenuItem value={''}>{'　'}</MenuItem>}
        {monthList.map((month) => (
          <MenuItem value={month} key={month}>
            {month}月
          </MenuItem>
        ))}
      </Select>
      {isForward && (
        <ChangeButton
          variant="outlined"
          disabled={
            yearMonthList.slice(-1)[0]?.year === year &&
            yearMonthList.slice(-1)[0]?.month === month
          }
          onClick={async () => {
            const yearMonth = yearMonthList.find(
              (ym) =>
                ym.year.padStart(4, '0') > year.padStart(4, '0') ||
                (ym.year === year &&
                  ym.month.padStart(2, '0') > month.padStart(2, '0'))
            );
            if (!yearMonth) {
              return;
            }
            await onChange?.(yearMonth.value);
          }}
        >
          <ArrowForwardIos fontSize="small" color="action" />
        </ChangeButton>
      )}
    </Box>
  );
}
