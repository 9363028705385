import { AxiosError } from 'axios';

/**
 * Typeguard function check if one unknown is an error or not
 * @param e
 * @returns
 */
export function isError(e: unknown): e is Error {
  return e instanceof Error;
}

/**
 * サービス共通のエラーを取得する。
 * @param error エラー
 * @returns サービス共通のエラー
 */
export function getError(error: unknown) {
  if (error instanceof AxiosError && error.response?.data?.message) {
    return new Error(error.response.data.message);
  }
  if (error instanceof Error) {
    return new Error(error.message);
  }
  return new Error(error?.toString());
}
