/**
 * 最大サイズに合わせてリサイズしたURLを取得する。
 * @param file ファイルまたはファイルのURL
 * @param maxWidth 最大横サイズ
 * @param maxHeight 最大縦サイズ
 * @param trimmingRatio トリミングさせる縦横比（横/縦）
 * @returns リサイズ後のURL
 */
export function resizeImageURL(
  file: Blob | string,
  maxWidth?: number,
  maxHeight?: number,
  trimmingRatio?: number
) {
  const promise = new Promise<string>((resolve, reject) => {
    try {
      if (!file) {
        resolve('');
      }
      const reader = new FileReader();
      reader.onload = async () => {
        const imgData = reader.result;
        const orgImage = new Image();
        orgImage.onload = function () {
          const orgWidth = orgImage.naturalWidth;
          const orgHeight = orgImage.naturalHeight;
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const image = new Image();
          // オリジナルが最大サイズより大きければ最大サイズを設定
          const maxWidth2 = maxWidth ?? orgWidth;
          const maxHeight2 = maxHeight ?? orgHeight;
          const dwBuf = orgWidth <= maxWidth2 ? orgWidth : maxWidth2;
          const dhBuf = orgHeight <= maxHeight2 ? orgHeight : maxHeight2;
          const dw =
            !trimmingRatio || dwBuf <= dhBuf * trimmingRatio
              ? dwBuf
              : dhBuf * trimmingRatio;
          const dh =
            !trimmingRatio || dhBuf <= dwBuf / trimmingRatio
              ? dhBuf
              : dwBuf / trimmingRatio;
          // オリジナルが最大サイズより大きければ縦または横をトリミング
          const sw =
            orgWidth < (orgHeight / dh) * dw ? orgWidth : (orgHeight / dh) * dw;
          const sh =
            orgHeight < (orgWidth / dw) * dh ? orgHeight : (orgWidth / dw) * dh;
          // 中央が残るようにトリミング
          const sx = (orgWidth - sw) / 2;
          const sy = (orgHeight - sh) / 2;
          canvas.width = dw;
          canvas.height = dh;
          image.onload = () => {
            ctx?.drawImage(image, sx, sy, sw, sh, 0, 0, dw, dh);
            resolve(canvas.toDataURL('image/png'));
          };
          image.src = imgData as string;
        };
        orgImage.src = imgData as string;
      };
      reader.onerror = () => reject(new Error('resizeImageURL:error'));
      reader.onabort = () => reject(new Error('resizeImageURL:abort'));
      if (typeof file === 'string') {
        void fetch(file).then((response) => {
          void response.blob().then((blob) => {
            reader.readAsDataURL(blob);
          });
        });
      } else {
        reader.readAsDataURL(file);
      }
    } catch (error) {
      reject(error);
    }
  });
  return promise;
}
