import { Box, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import { forwardRef, RefObject } from 'react';

export interface ProductRepeatWeekProps {
  error?: boolean;
  helperText?: string;
  onChange?: (value: number[]) => void;
  value?: number[];
}

export const ProductRepeatWeek = forwardRef(function (
  { value, onChange, error, helperText }: ProductRepeatWeekProps,
  ref
) {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" alignItems="center" width="35rem">
        {['日', '月', '火', '水', '木', '金', '土'].map((w, i) => (
          <FormControlLabel
            key={i}
            ref={i === 0 ? (ref as RefObject<HTMLButtonElement>) : undefined}
            control={
              <Checkbox
                checked={value?.some((v) => v === i)}
                onChange={(e, checked) => {
                  const newValue = checked
                    ? Array.from(new Set([...(value ?? []), i])).sort()
                    : value?.filter((w) => w !== i);
                  onChange?.(newValue ?? []);
                }}
              />
            }
            label={`${w}曜`}
          />
        ))}
      </Box>
      {helperText && (
        <FormHelperText error={error} sx={{ ml: 2 }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
});
