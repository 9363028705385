import axios from 'axios';
import { ChangeEvent } from 'react';

/**
 * Process of uploading a file to google storage, using a file input element.
 * Three steps:
 * 1. Get a signed url from the backend
 * 2. Upload the file to the signed url (directly to google storage)
 * 3. Post the url to the backend, backend will process the url and save it to the database
 * @param event: File change event
 * @param fetchSignedUrl: Function to get a signed url from the backend
 * @param onResponseUrlReceived: Function called when the upload is done and get response from google storage, usually the function will post the url to the backend
 * blocks-2a18
 */
export async function uploadFileByEvent(
  event: ChangeEvent<HTMLInputElement>,
  fetchSignedUrl: (fileType: string, fileSize: number) => Promise<string>,
  onResponseUrlReceived: (responseUrl: string) => Promise<void>
) {
  let uploadResponse: Response;
  if (event?.target?.files && event.target.files[0]) {
    const file = event.target.files[0];
    const signedUrl = await fetchSignedUrl(file.type, file.size);
    uploadResponse = await fetch(signedUrl, {
      body: file,
      method: 'PUT',
    });
  } else {
    throw new Error('no file detected');
  }
  await onResponseUrlReceived(uploadResponse.url);
}

/**
 * Process of uploading a file to google storage, using a blob
 * Three steps:
 * 1. Get a signed url from the backend
 * 2. Upload the file to the signed url (directly to google storage)
 * 3. Post the url to the backend, backend will process the url and save it to the database
 * @param blob
 * @param fetchSignedUrl
 * @param onResponseUrlReceived
 * blocks-2a18
 */
export async function uploadBlob(
  blob: Blob,
  fetchSignedUrl: (fileType: string, fileSize: number) => Promise<string>,
  onResponseUrlReceived: (responseUrl: string) => Promise<void>
): Promise<void> {
  const signedUrl = await fetchSignedUrl(blob.type, blob.size);
  const uploadResponse = await axios.put(signedUrl, blob, {
    headers: {
      'Content-Type': blob.type,
    },
  });
  if (!uploadResponse.config.url) {
    throw new Error("Can't get url from response");
  }
  await onResponseUrlReceived(uploadResponse.config.url);
}

export async function getUploadedFileUrl(
  blob: Blob,
  signedUrl: string
): Promise<string> {
  // Google Store にPUTして、ファイルをアップロードする
  const uploadResponse = await axios.put(signedUrl, blob, {
    headers: {
      'Content-Type': blob.type,
    },
  });
  if (!uploadResponse.config.url) {
    throw new Error("Can't get url from response");
  }
  return uploadResponse.config.url;
}
