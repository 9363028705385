import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { Box, useTheme, BoxProps } from '@mui/material';

type IconProps = Parameters<typeof StarIcon>[0];
export interface StarsRatingProps extends Omit<BoxProps, 'onChange'> {
  count?: number;
  iconProps?: IconProps;
  input?: boolean;
  maxRate?: number;
  onChange?: (rate: number) => void;
  rate?: number;
}

export function StarsRating({
  count,
  maxRate,
  rate,
  iconProps,
  input,
  onChange,
  ...props
}: StarsRatingProps) {
  const theme = useTheme();
  const count2 = count ?? 5;
  const maxRate2 = maxRate ?? 5;
  const rate2 = ((rate ?? 0) / maxRate2) * count2;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...props}>
      {Array.from(Array(count2 ?? 5).keys(), (z) => z + 1).map((i) => (
        <Box
          key={i}
          tabIndex={input ? 0 : undefined}
          onClick={() => {
            onChange?.(i * (maxRate2 / count2));
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onChange?.(i);
            }
          }}
          display="flex"
        >
          {i <= Math.floor(rate2) ? (
            <StarIcon
              {...iconProps}
              sx={[
                {
                  color: theme.customPalette.yellow,
                },
                ...(!iconProps?.sx
                  ? []
                  : Array.isArray(iconProps.sx)
                  ? iconProps.sx
                  : [iconProps.sx]),
              ]}
            />
          ) : i - 0.5 <= rate2 && rate2 < i ? (
            <StarHalfIcon
              {...iconProps}
              sx={[
                {
                  color: theme.customPalette.yellow,
                },
                ...(!iconProps?.sx
                  ? []
                  : Array.isArray(iconProps.sx)
                  ? iconProps.sx
                  : [iconProps.sx]),
              ]}
            />
          ) : (
            <StarBorderIcon
              {...iconProps}
              sx={[
                ...(!iconProps?.sx
                  ? []
                  : Array.isArray(iconProps.sx)
                  ? iconProps.sx
                  : [iconProps.sx]),
                {
                  color: theme.customPalette.lightGray,
                },
              ]}
            />
          )}
        </Box>
      ))}
    </Box>
  );
}
