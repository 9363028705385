import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Grid,
  FormControl,
  FormLabel,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import z from 'zod';

import { register } from '@app/adapter/user-service';
import {
  isLoading,
  snackbarOpenState,
  snackbarTextState,
} from '@app/domain/app';
import { passwordPattern } from '@app/utils/constants';
import { isError } from '@app/utils/error';

interface RegistrationFormType {
  confirmPassword: string;
  email: string;
  password: string;
  privacyPolicy: boolean;
  userAgreement: boolean;
}

export function RegistrationForm(): ReactElement {
  const navigate = useNavigate();
  const theme = useTheme();
  const setIsLoading = useSetRecoilState(isLoading);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handlePasswordVisibility = () => setIsPasswordVisible((prev) => !prev);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid },
  } = useForm<RegistrationFormType>({
    defaultValues: {
      confirmPassword: '',
      email: '',
      password: '',
      privacyPolicy: false,
      userAgreement: false,
    },
    mode: 'all',
  });

  const validator = {
    confirmPassword: z
      .string()
      .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
      .max(24, '24文字以下のパスワードを入力してください')
      .regex(passwordPattern, {
        message: '英字と数字を組み合わせてください',
      })
      .refine(
        (val) => val === getValues().password,
        'パスワードが一致しません'
      ),
    email: z.string().email(),
    password: z
      .string()
      .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
      .max(24, '24文字以下のパスワードを入力してください')
      .regex(passwordPattern, {
        message: '英字と数字を組み合わせてください',
      }),
    privacyPolicy: z.literal(true),
    userAgreement: z.literal(true),
  };

  const onSubmitForm = useCallback(
    async ({ email, password }: RegistrationFormType) => {
      setIsLoading(true);
      try {
        await register(email, password);
        navigate('/waiting-email-verify');
      } catch (error: unknown) {
        let errorMsg = '登録に失敗しました';
        if (isError(error)) {
          if (error.message.includes('has been registered in system')) {
            errorMsg = '既にこのメールアドレスは登録済みです';
          } else {
            errorMsg += `, ${error.message}`;
          }
        }
        setSnackbarText(errorMsg);
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    },
    [navigate, setSnackbarOpen, setSnackbarText, setIsLoading]
  );

  const validateField =
    (field: keyof RegistrationFormType) => (value: string) => {
      const result = validator[field].safeParse(value);
      if (result.success) return undefined;
      const error = result.error?.errors[0];
      if (
        field === 'email' &&
        error?.code === 'invalid_string' &&
        value === ''
      ) {
        return 'メールアドレスを入力してください';
      }
      return error?.message;
    };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid
        container
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        <Grid item xs={12} sx={{ mb: 3, textAlign: 'center' }}>
          <Grid item xs={12} sx={{ mb: 3, mt: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel sx={{ display: 'flex' }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={600}
                  sx={{ textAlign: 'left' }}
                >
                  メールアドレス
                </Typography>
                <Typography
                  sx={{ textAlign: 'left' }}
                  color={theme.customPalette.alert}
                >
                  *
                </Typography>
              </FormLabel>
              <Controller
                name="email"
                control={control}
                rules={{ validate: validateField('email') }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    className="required"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="メールアドレスを入力"
                    sx={{ width: '328px' }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <FormLabel sx={{ display: 'flex' }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={600}
                  sx={{ textAlign: 'left' }}
                >
                  パスワード
                </Typography>
                <Typography
                  sx={{ textAlign: 'left' }}
                  color={theme.customPalette.alert}
                >
                  *
                </Typography>
              </FormLabel>
              <Controller
                name="password"
                control={control}
                rules={{ validate: validateField('password') }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    className="required"
                    {...field}
                    type={isPasswordVisible ? 'text' : 'password'}
                    error={!!error}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="パスワードを入力"
                    sx={{ width: '328px' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {isPasswordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Typography
                sx={{ color: theme.customPalette.lightGray, textAlign: 'left' }}
              >
                8文字以上、英数字含む（記号を除く）
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel sx={{ display: 'flex' }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={600}
                  sx={{ textAlign: 'left' }}
                >
                  確認用パスワード
                </Typography>
                <Typography
                  sx={{ textAlign: 'left' }}
                  color={theme.customPalette.alert}
                >
                  *
                </Typography>
              </FormLabel>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{ validate: validateField('confirmPassword') }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    className="required"
                    {...field}
                    type={isPasswordVisible ? 'text' : 'password'}
                    error={!!error}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="上記と同じパスワードを入力"
                    sx={{ width: '328px' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {isPasswordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ width: '328px' }}
              disabled={!isValid}
            >
              送信
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
