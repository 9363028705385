import { zodResolver } from '@hookform/resolvers/zod';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  getLocationByLevel,
  getLocationByParentId,
  getLocationList,
} from '@app/adapter/catalog-service';
import {
  createChangeRequest,
  createOrganization,
  handleCheckAndResizeImageUpload,
  updateOrganizationInfo,
} from '@app/adapter/organization-service';
import { EquipmentCheckModal } from '@app/components/Registration/Confirmation/EquipmentCheckModal';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { UploadFile } from '@app/components/UploadFile/UploadFile';
import { loggedInUserState, userAuthInfoSelector } from '@app/domain/app';
import { organization as organizationAtom } from '@app/domain/organization';
import {
  OrganizationFormData,
  OrganizationFormValidator,
  ORGANIZATION_FORM_DEFAULT_VALUES,
} from '@app/schemas/registration';
import {
  Organization,
  ORGANIZATION_STATUS,
  ORGANIZATION_STATUS_LABELS,
} from '@app/types/organization';
import { urlToBase64 } from '@app/utils/base64';
import { CHARTS_LABEL, SELECTABLE_CHARTS_TYPE } from '@app/utils/constants';
import { isError } from '@app/utils/error';
import { HTTP_STATUS_CODE_NO_CONTENT } from '@app/utils/httpStatusCode';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';
import { OrganizationListLocationState } from '@app/views/OrganizationList';

const SELECTABLE_ORGANIZATION_STATUS = {
  ...ORGANIZATION_STATUS,
  ALL: '__ALL__', // 重複しないように
};

const DEFAULT_IMAGE_PATH = process.env.PUBLIC_URL + '/clinic-sample-image.png';

// Organization interface の Object を FormData interface に mapping
const mappingOrganizationFormData = (
  editTarget: Organization
): OrganizationFormData => {
  const mapped = Object.entries(ORGANIZATION_FORM_DEFAULT_VALUES).reduce(
    (mapping, [key, value]) => {
      const matched = Object.entries(editTarget).find(
        ([targetKey]) => targetKey === key
      );
      // 型の確認
      if (!matched || typeof matched[1] !== typeof value) {
        return mapping;
      }
      return { ...mapping, [key]: matched[1] };
    },
    { ...ORGANIZATION_FORM_DEFAULT_VALUES } as OrganizationFormData
  );
  return mapped;
};

//
// Component
//
export type SelectableOrganizationStatus =
  (typeof SELECTABLE_ORGANIZATION_STATUS)[keyof typeof SELECTABLE_ORGANIZATION_STATUS];

export function OrganizationCreateEdit() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId } = useParams();
  const setSnackbar = useSetSnackbar();
  const currentOrganization = useRecoilValue(organizationAtom);
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [isUploadAlertDialog, setIsUploadAlertDialog] = useState(false);
  const isEditMode = Boolean(orgId);

  //
  // react-hook-form
  //
  const orgDefaultValue = isEditMode
    ? // Edit mode
      {
        ...mappingOrganizationFormData(currentOrganization),
        privacyPolicy: true,
        userAgreement: true,
      }
    : // Create mode
      {
        ...ORGANIZATION_FORM_DEFAULT_VALUES,
        description: currentOrganization?.description || '',
        ownerId: loggedInUser?.id || '',
        privacyPolicy: true,
        userAgreement: true,
      };

  const { control, watch, formState, setValue, trigger } =
    useForm<OrganizationFormData>({
      defaultValues: orgDefaultValue,
      mode: 'onChange',
      resolver: zodResolver(OrganizationFormValidator),
    });
  const { errors, isValid } = formState;
  const [registerData, setRegisterData] =
    useState<OrganizationFormData>(orgDefaultValue);
  const watchAddressLine1 = watch('addressLine1');
  const shouldSkipValidation = watch('shouldSkipValidation');

  //
  // fileUpload
  //
  const [iconImageFile, setIconImageFile] = useState<null | string>(null);
  const [logoImageFile, setLogoImageFile] = useState<null | string>(null);

  //
  // 都道府県選択
  //
  const [prefectureData, setPrefectureData] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [cityData, setCityData] = useState<Array<{ id: string; name: string }>>(
    []
  );
  const [isCityFetching, setIsCityFetching] = useState<boolean>(false);
  useEffect(() => {
    if (prefectureData.length > 0) {
      return; // skip
    }
    const fetchPrefectureData = async () => {
      try {
        const response = await getLocationByLevel();
        if (response.data?.value) {
          setPrefectureData(
            response.data.value.map(({ id, name }) => ({ id, name }))
          );
        }
      } catch (error) {
        console.error('Failed to fetch prefecture data:', error);
      }
    };
    void (async () => {
      await fetchPrefectureData();
      if (isEditMode && currentOrganization.addressLine1) {
        await fetchCityData(currentOrganization.addressLine1);
      }
    })();
  }, [prefectureData, setPrefectureData, isEditMode, currentOrganization]);

  const fetchCityData = async (prefectureId: string) => {
    try {
      setIsCityFetching(true);

      const response = await getLocationByParentId(prefectureId);
      const values = [response.data.value];
      if (response.data['@nextLink']) {
        const addResult = await getLocationList({
          nextLink: response.data['@nextLink'],
        });
        values.push(addResult.data.value);
      }
      setCityData(values.flat());
    } catch (error) {
      console.error('Failed to fetch city data:', error);
    } finally {
      setIsCityFetching(false);
    }
  };
  const handlePrefectureChange = async (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value as string;
    const selectedPrefecture = prefectureData.find(
      (pref) => pref.id === selectedId
    );
    // 都道府県の再選択時に市町村の選択を初期化
    setValue('addressLine2', '');
    // setCityData([]);

    setRegisterData((prev) => ({
      ...prev,
      addressLine1: selectedPrefecture ? selectedPrefecture.id : '',
    }));
    void fetchCityData(selectedId);
  };

  const handleCityChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCityId = e.target.value;
    const selectedCity = cityData.find((city) => city.id === selectedCityId);
    if (!selectedCity) return;

    const selectedPrefectureName = prefectureData.find(
      (pref) => pref.id === watchAddressLine1
    )?.name;
    const addressByCity = `${selectedPrefectureName}${selectedCity.name}`;

    // 連結した都道府県と市区郡をcustomField.addressByCityに格納
    setRegisterData((prev) => ({
      ...prev,
      addressLine2: selectedCity?.id as string,
      customFields: {
        ...prev.customFields,
        addressByCity,
      },
    }));
    setValue('customFields.addressByCity', addressByCity);
  };

  //
  // カルテ
  //
  const initialCheckedCharts = isEditMode
    ? Object.entries(CHARTS_LABEL)
        .filter(([, value]) =>
          currentOrganization.customFields?.electronicCharts?.includes(value)
        )
        .map(([key]) => key)
    : [];
  const [checkedCharts, setCheckedCharts] =
    useState<string[]>(initialCheckedCharts);

  const handleSelectedCharts = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const currentChartsType = event.target.value;
    const nextCharts = checked
      ? [...checkedCharts, currentChartsType]
      : checkedCharts.filter((item) => item !== currentChartsType);
    setCheckedCharts(nextCharts);
    const electronicCharts = nextCharts
      .map((item: string) => CHARTS_LABEL[item] || null)
      .filter((item) => !!item) as Array<string>;
    setRegisterData((prev) => ({
      ...prev,
      customFields: {
        ...prev.customFields,
        electronicCharts,
      },
    }));

    // NOTE:以下２行はスキーマにcustomFields.electronicChartsを追加した関係で実装。本来はrhfのControllerを使ったほうが良いが、修正範囲が大きくなるためsetValue、triggerでバリデーションを制御。
    setValue('customFields.electronicCharts', electronicCharts);
    void trigger('customFields.electronicCharts');
  };

  const handleSelectedEquipments = (equipments: string[]) => {
    setRegisterData((prev) => ({
      ...prev,
      customFields: {
        ...prev.customFields,
        equipments,
      },
    }));
  };

  //
  // Submit
  //
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handleSubmitOrganization = async () => {
    isEditMode
      ? await handleSubmitOrganizationEdit()
      : await handleSubmitOrganizationCreate();
  };

  const handleSubmitOrganizationCreate = useCallback(
    async (/*formData: OrganizationFormData*/) => {
      setIsSubmitting(true);
      const {
        name,
        postalCode,
        addressLine1,
        addressLine2,
        addressLine3,
        phoneNumber,
        description,
        customFields,
      } = registerData;

      try {
        const iconImageData = await urlToBase64(
          iconImageFile || DEFAULT_IMAGE_PATH
        );
        const logoImageData = await urlToBase64(
          logoImageFile || DEFAULT_IMAGE_PATH
        );

        const creatingOrgData = {
          addressLine1,
          addressLine2,
          addressLine3,
          certificateImageData: '', // 今のところ使っていない
          customFields: {
            ...customFields,
            ownerUserId: userAuthInfoState.id,
          },
          description,
          iconImageData,
          logoImageData,
          name,
          ownerId: userAuthInfoState.id,
          phoneNumber,
          postalCode,
        };

        await createOrganization(creatingOrgData);

        navigate('/organizations', {
          state: {
            referrer: location.pathname,
            success: true,
          } as OrganizationListLocationState,
        });
      } catch (e) {
        if (isError(e)) {
          console.error(e.message);
        }
        setSnackbar(true, '医療機関情報の登録が出来ませんでした');
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      registerData,
      location,
      navigate,
      setSnackbar,
      userAuthInfoState,
      iconImageFile,
      logoImageFile,
    ]
  );

  const handleSubmitOrganizationEdit = useCallback(
    async (/*formData: OrganizationFormData*/) => {
      setIsSubmitting(true);
      const {
        name,
        postalCode,
        addressLine1,
        addressLine2,
        addressLine3,
        phoneNumber,
        description,
        customFields,
        // certificateImageUri,
      } = registerData;

      const isRequiredChangeRequest =
        name !== currentOrganization.name ||
        addressLine1 !== currentOrganization.addressLine1 ||
        addressLine2 !== currentOrganization.addressLine2 ||
        addressLine3 !== currentOrganization.addressLine3 ||
        postalCode !== currentOrganization.postalCode;

      try {
        // Create change request to update organization
        // TODO: Adminの確認が必要

        if (isRequiredChangeRequest) {
          const changeRequestData = {
            addressLine1,
            addressLine2,
            addressLine3,
            name,
            postalCode,
          };
          const changeResult = await createChangeRequest(
            currentOrganization.id,
            changeRequestData
          );
          if (changeResult.status !== HTTP_STATUS_CODE_NO_CONTENT) {
            throw new Error(
              `異常値がレスポンスされました:change(${changeResult.status})`
            );
          }
        }

        const iconImageData =
          (iconImageFile && (await urlToBase64(iconImageFile))) || undefined;
        const logoImageData =
          (logoImageFile && (await urlToBase64(logoImageFile))) || undefined;

        const updateRequestData = {
          customFields: { ...customFields, mainImages: '' },
          description,
          iconImageData,
          logoImageData,
          phoneNumber,
        };

        await updateOrganizationInfo(currentOrganization.id, updateRequestData);

        navigate('/organizations', {
          state: {
            referrer: location.pathname,
            success: true,
          } as OrganizationListLocationState,
        });
      } catch (e) {
        if (isError(e)) {
          console.error(e.message);
        }
        setSnackbar(true, '医療機関情報の登録が出来ませんでした');
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      registerData,
      location,
      navigate,
      setSnackbar,
      currentOrganization,
      iconImageFile,
      logoImageFile,
    ]
  );

  const handleUploadImageFile = async (dataImage: string) => {
    const { isDifferentExpectedRatios, logoImageUrl, iconImageUrl } =
      await handleCheckAndResizeImageUpload(dataImage);
    console.log(isDifferentExpectedRatios);
    setIsUploadAlertDialog(isDifferentExpectedRatios);
    setLogoImageFile(logoImageUrl);
    setIconImageFile(iconImageUrl);
  };

  //
  // Render
  //

  if (!loggedInUser) {
    return null; // guard
  }

  // edit mode では市町村までfetch完了してからRendering
  if (prefectureData.length === 0 || (isEditMode && cityData.length === 0)) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
        <Typography ml={2}>読み込み中</Typography>
      </Box>
    );
  }

  return (
    <>
      <Stack direction="row" spacing={2} mb={2}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => navigate('/organizations')}
          sx={{ height: '40px', width: '40px' }}
        >
          <ArrowBackIcon />
        </Button>
        <PageTitle title="医療機関管理" />
      </Stack>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5">
            {isEditMode ? `${currentOrganization.name || ''}` : '新規作成'}
          </Typography>
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            disabled={!isValid}
            loading={isSubmitting}
            onClick={() => handleSubmitOrganization()}
          >
            保存
          </LoadingButton>
        </Stack>
        <Divider />
        {isEditMode && (
          <>
            <Typography m={2}>
              {`ステータス ${
                (currentOrganization.status &&
                  ORGANIZATION_STATUS_LABELS[currentOrganization.status]) ||
                ''
              }`}
            </Typography>
            <Divider />
          </>
        )}
        <CardContent>
          <Stack spacing={2}>
            <FormControl>
              <Typography variant="body2">法人名</Typography>
              <Controller
                name="description"
                control={control}
                defaultValue={registerData.description}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={registerData.description}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="法人名を入力"
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <Typography variant="body2">
                医療機関名
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="name"
                control={control}
                defaultValue={registerData.name}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    className="required"
                    {...field}
                    disabled={isEditMode /* TODO:API側制約 */}
                    value={registerData.name}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="医療機関名を入力"
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography variant="body2">
                担当者連絡先電話番号
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue={registerData.phoneNumber}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    className="required"
                    {...field}
                    value={registerData.phoneNumber}
                    type="tel"
                    error={!!error}
                    helperText={error?.message}
                    placeholder="担当者連絡先電話番号（ハイフンなし）で入力"
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        phoneNumber: e.target.value,
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography variant="body2">
                住所
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Stack spacing={1}>
                <Controller
                  name="postalCode"
                  control={control}
                  defaultValue={registerData.postalCode || ''}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      className="required"
                      {...field}
                      value={registerData.postalCode}
                      error={!!error}
                      helperText={error?.message}
                      placeholder="郵便番号を入力"
                      disabled={isEditMode /* TODO:API側制約 */}
                      onChange={(e) => {
                        setRegisterData((prev) => ({
                          ...prev,
                          postalCode: e.target.value,
                        }));
                        field.onChange(e);
                      }}
                      sx={{ width: 250 }}
                    />
                  )}
                />
                {errors.postalCode &&
                errors.postalCode.type !== 'invalid_string' ? (
                  <FormHelperText error>
                    正しい郵便番号を入力してください
                  </FormHelperText>
                ) : undefined}
                <Stack direction="row" spacing={1} alignItems="center">
                  <FormControl fullWidth>
                    <Controller
                      name="addressLine1"
                      control={control}
                      rules={{ required: '都道府県を選択してください' }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl variant="outlined" error={!!error}>
                          <Select
                            required
                            {...field}
                            disabled={isEditMode /* TODO:API側制約 */}
                            displayEmpty
                            renderValue={(selectedId) => {
                              const selectedName =
                                prefectureData.find(
                                  (pref) => pref.id === selectedId
                                )?.name || '';
                              if (selectedName) {
                                return selectedName;
                              }
                              return (
                                <span
                                  style={{
                                    color: theme.customPalette.lightGray,
                                  }}
                                >
                                  都道府県を選択
                                </span>
                              );
                            }}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              void handlePrefectureChange(e);
                            }}
                          >
                            <MenuItem value="" disabled>
                              都道府県を選択
                            </MenuItem>
                            {prefectureData.map((prefecture) => (
                              <MenuItem
                                key={prefecture.id}
                                value={prefecture.id}
                              >
                                {prefecture.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Controller
                      name="addressLine2"
                      control={control}
                      defaultValue={registerData.addressLine2}
                      render={({ field, fieldState: { error } }) =>
                        isCityFetching ? (
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress />
                            <Typography ml={2}>更新中</Typography>
                          </Stack>
                        ) : (
                          <TextField
                            {...field}
                            className="required"
                            disabled={isEditMode /* TODO:API側制約 */}
                            value={cityData.length > 0 ? field.value : ''}
                            error={!!error}
                            helperText={error?.message}
                            placeholder="市町村を選択"
                            select
                            onChange={(e) => {
                              handleCityChange(e);
                              field.onChange(e);
                            }}
                          >
                            {cityData.length > 0 ? (
                              cityData.map((city) => (
                                <MenuItem key={city.id} value={city.id}>
                                  {city.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value="">(loading)</MenuItem>
                            )}
                          </TextField>
                        )
                      }
                    />
                  </FormControl>
                </Stack>
                <FormControl>
                  <Controller
                    name="addressLine3"
                    control={control}
                    defaultValue={registerData.addressLine3}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        className="required"
                        {...field}
                        disabled={isEditMode /* TODO:API側制約 */}
                        value={field.value}
                        error={!!error}
                        helperText={error?.message}
                        placeholder="町名・番地を入力"
                        onChange={(e) => {
                          setRegisterData((prev) => ({
                            ...prev,
                            addressLine3: e.target.value,
                          }));
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    name="customFields.addressLine4"
                    control={control}
                    defaultValue={registerData.customFields.addressLine4}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="建物名・部屋番号を入力"
                        onChange={(e) => {
                          const updatedCustomFields = {
                            ...registerData.customFields,
                            addressLine4: e.target.value,
                          };
                          setRegisterData((prev) => ({
                            ...prev,
                            customFields: updatedCustomFields,
                          }));
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </FormControl>
            <FormControl>
              <Typography variant="body2">
                アクセス情報
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="customFields.access"
                control={control}
                defaultValue={registerData.customFields.access}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    className="required"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="アクセス情報を入力   例）JR山手線新宿駅から徒歩5分"
                    rows={2}
                    multiline
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        customFields: {
                          ...prev.customFields,
                          access: e.target.value,
                        },
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography variant="body2">医療機関のWebページ</Typography>
              <Controller
                name="customFields.clinicUrl"
                control={control}
                defaultValue={registerData.customFields.clinicUrl}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="https://www.pro-med.com"
                    multiline
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        customFields: {
                          ...prev.customFields,
                          clinicUrl: e.target.value,
                        },
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography variant="body2">
                標榜科目
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="customFields.department"
                control={control}
                defaultValue={registerData.customFields.department}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    className="required"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="一般内科、循環器内科"
                    rows={2}
                    multiline
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        customFields: {
                          ...prev.customFields,
                          department: e.target.value,
                        },
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography variant="body2">
                院長名
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    className="required"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setValue('shouldSkipValidation', isChecked);
                      if (isChecked) {
                        const ownerName = ` ${loggedInUser.customFields.familyName} ${loggedInUser.customFields.firstName}`;
                        setValue('customFields.ownerName', ownerName);
                        setRegisterData((prev) => ({
                          ...prev,
                          customFields: {
                            ...prev.customFields,
                            ownerName,
                          },
                        }));
                      }
                    }}
                  />
                }
                label="担当者ユーザーの名前と同様"
              />
              <Controller
                name="customFields.ownerName"
                control={control}
                defaultValue={registerData.customFields.ownerName}
                rules={{ required: !shouldSkipValidation }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    error={!shouldSkipValidation && !!error}
                    helperText={!shouldSkipValidation && error?.message}
                    placeholder="院長名を入力"
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        customFields: {
                          ...prev.customFields,
                          ownerName: e.target.value,
                        },
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <Box>
              <Typography variant="body2">検査設備</Typography>
              <EquipmentCheckModal
                selectedItems={registerData.customFields.equipments}
                onUpdate={handleSelectedEquipments}
              />
            </Box>
            <FormControl>
              <Typography variant="body2">検査設備（その他）</Typography>
              <Controller
                name="customFields.equipmentOther"
                control={control}
                defaultValue={registerData.customFields.equipmentOther}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="その他の検査設備を入力"
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        customFields: {
                          ...prev.customFields,
                          equipmentOther: e.target.value,
                        },
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              component="fieldset"
              error={
                Boolean(errors.customFields?.electronicCharts) ||
                !checkedCharts.length
              }
            >
              <Typography variant="body2">
                カルテ
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      className="required"
                      value={SELECTABLE_CHARTS_TYPE.PAPER}
                      checked={checkedCharts.includes(
                        SELECTABLE_CHARTS_TYPE.PAPER
                      )}
                      onChange={handleSelectedCharts}
                    />
                  }
                  label="紙カルテ"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className="required"
                      value={SELECTABLE_CHARTS_TYPE.ELECTRONIC}
                      checked={checkedCharts.includes(
                        SELECTABLE_CHARTS_TYPE.ELECTRONIC
                      )}
                      onChange={handleSelectedCharts}
                    />
                  }
                  label="電子カルテ"
                />
              </Stack>
              {errors.customFields?.electronicCharts && (
                <FormHelperText>
                  {errors.customFields?.electronicCharts?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <Typography variant="body2">電子カルテメーカー</Typography>
              <Controller
                name="customFields.electronicChartMaker"
                control={control}
                defaultValue={registerData.customFields.electronicChartMaker}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="電子カルテメーカーを入力"
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        customFields: {
                          ...prev.customFields,
                          electronicChartMaker: e.target.value,
                        },
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography variant="body2">
                医療機関イメージ・写真(サイズは最大5MBまで、縦横比３：２を推奨)
              </Typography>
              {
                // 画像設定済みの場合
                isEditMode && !logoImageFile && (
                  <div
                    style={{
                      height: '300px',
                      width: '100%',
                    }}
                  >
                    <img
                      alt="organization"
                      style={{
                        height: '100%',
                        objectFit: 'contain',
                        width: 'auto',
                      }}
                      src={
                        currentOrganization.logoImageUri ||
                        currentOrganization.iconImageUri
                      }
                    />
                  </div>
                )
              }
              <UploadFile
                fileUrl={logoImageFile || ''}
                setImageUrl={handleUploadImageFile}
                addImageButtonText="画像をアップロード"
                sx={{ padding: '10px 10px 10px 0' }}
              />
            </FormControl>
            <FormControl>
              <Typography variant="body2">
                医療機関の一言アピール(200文字まで)
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="customFields.clinicAppealMessage"
                control={control}
                defaultValue={registerData.customFields.clinicAppealMessage}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    className="required"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="当院は、県内では珍しい診療科を持ち、多種多様な症例を経験できます。急性期治療病棟での入院も対応できます。コメディカルの人材も豊富でOT、CP、ST、PTと連携することができます。
                    定期非常勤のドクターも随時募集中ですので、条件に合わずともぜひお声がけください。"
                    rows={9}
                    multiline
                    onChange={(e) => {
                      setRegisterData((prev) => ({
                        ...prev,
                        customFields: {
                          ...prev.customFields,
                          clinicAppealMessage: e.target.value,
                        },
                      }));
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
          </Stack>
        </CardContent>
      </Card>
      <ConfirmDialog
        title="画像の縦横比が異なります、求人票のデザインが崩れるため、修正してください"
        closeButtonText="OK"
        open={isUploadAlertDialog}
        onClose={() => setIsUploadAlertDialog(false)}
      />
    </>
  );
}
