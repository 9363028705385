import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { getUserReviewsByOrg } from '@app/adapter/review-service';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';
import { Review } from '@app/types/review';

/**
 * 評価リストを取得するフック
 * reviewsDisplayCountを増加させることで取得件数増加可能（増加数は100件まで）
 * @returns 評価リスト
 */
export function useReviewsAdd(
  userId: string | undefined,
  reviewsDisplayCount = 100,
  pageSize = 100
) {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [reviews, setReviews] = useState(undefined as Review[] | undefined);
  const [reviewsTotal, setReviewsTotal] = useState(
    undefined as number | undefined
  );
  const load = useCallback(async () => {
    try {
      if (!userId) {
        return;
      }
      if (
        reviewsTotal !== undefined &&
        reviewsTotal <= (reviews?.length ?? 0)
      ) {
        return;
      }
      if (reviewsDisplayCount <= (reviews?.length ?? 0)) {
        return;
      }
      const result = await getUserReviewsByOrg(userId, {
        order: 'createdAt desc',
        pageNumber: Math.floor(reviewsDisplayCount / 100),
        pageSize,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setReviewsTotal(result.data.total);
      setReviews([...(reviews ?? []), ...result.data.value]);
    } catch (error) {
      setSnackbarText(
        `評価の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [
    userId,
    reviewsDisplayCount,
    pageSize,
    reviews,
    reviewsTotal,
    setSnackbarOpen,
    setSnackbarText,
  ]);
  const reloadReviews = useCallback(async () => {
    try {
      if (!userId) {
        return;
      }
      const result = await getUserReviewsByOrg(userId, {
        order: 'createdAt desc',
        pageNumber: 0,
        pageSize,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setReviewsTotal(result.data.total);
      setReviews(result.data.value);
    } catch (error) {
      setSnackbarText(
        `評価の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [userId, pageSize, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return { reloadReviews, reviews, reviewsTotal, setReviews, setReviewsTotal };
}
