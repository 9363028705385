import { Box, CircularProgress } from '@mui/material';

export function LoadingSpinner() {
  return (
    <Box
      sx={{
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        position: 'fixed',
        width: '100%',
        zIndex: '999',
      }}
    >
      <CircularProgress />
      <br />
      読み込み中
    </Box>
  );
}
