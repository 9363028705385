import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { endOfMonth, format, parse, startOfMonth, subMonths } from 'date-fns';
import {
  ComponentProps,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { setProductsStatus } from '@app/adapter/catalog-service';
import {
  getOrdersListWithFilter,
  updateOrderStatus,
} from '@app/adapter/order-service';
import { CancelDialog } from '@app/components/Orders/CancelDialog';
import { OrderTableComponent } from '@app/components/Orders/OrderTableComponent';
import {
  CustomTabPanel,
  TabsComponent,
} from '@app/components/Orders/TabsComponent';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import { MonthSelect } from '@app/components/Shared/MonthSelect';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SearchTextBox } from '@app/components/Shared/SearchTextBox';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';
import { tabIndexState } from '@app/domain/order';
import { organizationSelector } from '@app/domain/organization';
import { SupportedOrderStatus, OrderLocalized } from '@app/types/order';
import { defaultOrderStatusFilters } from '@app/utils/constants';
import { isError } from '@app/utils/error';
import { convertOrderLocalized } from '@app/utils/order';

export function Orders(): ReactElement {
  const theme = useTheme();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [tabIndex, setTabIndex] = useRecoilState(tabIndexState);
  const [keywordFilter, setKeywordFilter] = useState('');
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [processingCount, setProcessingCount] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  });
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const [isOpenAcceptedDialog, setIsOpenAcceptedDialog] = useState(false);
  const [isOpenCanceledDialog, setIsOpenCanceledDialog] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isProductArchived, setIsProductArchived] = useState(false);
  const [allOrders, setAllOrders] = useState<OrderLocalized[]>([]);
  const [pendingOrders, setPendingOrders] = useState<OrderLocalized[]>([]);
  const [acceptedOrders, setAcceptedOrders] = useState<OrderLocalized[]>([]);
  const [processingOrders, setProcessingOrders] = useState<OrderLocalized[]>(
    []
  );

  // データ取得に使用するタイマー（無駄な検索が走らないための対策用）
  const loadTimerRef = useRef({
    ms: 0,
    timeoutId: undefined as NodeJS.Timeout | undefined,
  });
  const selectedOrders = selectedIDs
    .map((id) => pendingOrders.find((o) => o.id === id) || '')
    .filter((o) => o) as OrderLocalized[];
  const selectedName = `チェックした${selectedOrders.length}名`;

  const getOrdersStatus = useCallback(
    async (status?: SupportedOrderStatus) => {
      if (!organizationState || !organizationState.id) {
        console.error(
          'organizationState or organizationState.id is not defined'
        );
        return;
      }
      try {
        const result = await getOrdersListWithFilter(organizationState.id, {
          order: 'productType,customFields.productDay,createdAt desc',
          pageNumber: pagination.page,
          pageSize: pagination.pageSize,
          statuses: status ? [status] : defaultOrderStatusFilters,
          ...(keywordFilter ? { keyword: keywordFilter } : {}),
          ...(startMonth && endMonth
            ? {
                dateRange: {
                  end: endOfMonth(
                    parse(endMonth, 'yyyy/M', new Date())
                  ).toISOString(),
                  isEmpty: true,
                  start: startOfMonth(
                    parse(startMonth, 'yyyy/M', new Date())
                  ).toISOString(),
                },
              }
            : {}),
        });
        if (result.status === 200) {
          const orderList = result.data.value.map((order) =>
            convertOrderLocalized(order)
          );
          switch (status) {
            case 'PENDING':
              setPendingOrders(orderList);
              setPendingCount(result.data.total);
              break;
            case 'ACCEPTED':
              setAcceptedOrders(orderList);
              setAcceptedCount(result.data.total);
              break;
            case 'PROCESSING':
              setProcessingOrders(orderList);
              setProcessingCount(result.data.total);
              break;
            case undefined:
              setAllOrders(orderList);
              setAllCount(result.data.total);
              break;
          }
        } else {
          setSnackbarText(`応募の取得に失敗しました, ${result.status}`);
          setSnackbarOpen(true);
        }
      } catch (error: unknown) {
        if (isError(error)) {
          setSnackbarText(`応募の取得に失敗しました, ${error.message}`);
        } else {
          setSnackbarText(`応募の取得に失敗しました`);
        }
        setSnackbarOpen(true);
      }
    },
    [
      organizationState,
      startMonth,
      endMonth,
      keywordFilter,
      pagination,
      setSnackbarOpen,
      setSnackbarText,
    ]
  );

  const getOrders = useCallback(async () => {
    setIsLoadingOrder(true);
    await getOrdersStatus('PENDING');
    setIsLoadingOrder(false);
    void getOrdersStatus('ACCEPTED');
    void getOrdersStatus('PROCESSING');
    void getOrdersStatus();
  }, [getOrdersStatus]);

  useEffect(() => {
    // NOTE:無駄な検索防止に一定時間検索待機
    clearTimeout(loadTimerRef.current.timeoutId);
    const timeoutId = setTimeout(() => {
      void getOrders();
    }, loadTimerRef.current.ms);
    loadTimerRef.current = { ms: 0, timeoutId };
  }, [getOrders]);

  const statusChange = useCallback(
    async (newStatus: SupportedOrderStatus) => {
      // TODO:ループしているが将来的にはAPIで一括更新したほうが良い
      for (const order of selectedOrders) {
        if (!order) {
          continue;
        }
        try {
          await updateOrderStatus(order.organization, order.id, newStatus);
        } catch (error) {
          setSnackbarText(
            `応募の更新に失敗しました, ${
              error instanceof Error ? error.message : error
            }`
          );
          setSnackbarOpen(true);
        }
      }
    },
    [selectedOrders, setSnackbarText, setSnackbarOpen]
  );

  const updateProductArchived = useCallback(async () => {
    const productIds = selectedOrders
      .map((o) => o?.lineItem.product as string)
      .filter((id) => id);
    if (!productIds.length) {
      return;
    }
    try {
      const result = await setProductsStatus(
        organizationState.id,
        productIds,
        'ARCHIVED'
      );
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
    } catch (error) {
      setSnackbarText(
        `求人の更新に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [selectedOrders, organizationState, setSnackbarText, setSnackbarOpen]);

  const handleClickAcceptedButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenAcceptedDialog(true);
    }, []);

  const handleChangeProductArchived: NonNullable<
    ComponentProps<typeof Checkbox>['onChange']
  > = useCallback((e, checked) => {
    setIsProductArchived(checked);
  }, []);

  const handleCloseAcceptedDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm) {
        setIsOpenAcceptedDialog(false);
        return;
      }
      setIsLoadingDialog(true);
      await statusChange('ACCEPTED');
      if (isProductArchived) {
        await updateProductArchived();
      }
      // TODO:採用メールを送信する
      setIsLoadingDialog(false);
      setIsOpenAcceptedDialog(false);
      setSnackbarText(`${selectedName}を採用しました。`);
      setSnackbarState('info');
      setSnackbarOpen(true);
      await getOrders();
    },
    [
      statusChange,
      isProductArchived,
      updateProductArchived,
      selectedName,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
      getOrders,
    ]
  );

  const handleClickCanceledButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenCanceledDialog(true);
    }, []);

  const handleSubmitCancelDialog = async () => {
    setIsOpenCanceledDialog(false);
    setSelectedIDs([]);
    await getOrders();
  };

  const handleStartDateChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadTimerRef.current = { ...loadTimerRef.current, ms: 500 }; //NOTE:無駄な検索防止に一定時間検索待機
    setStartMonth(value);
  }, []);

  const handleEndDateChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadTimerRef.current = { ...loadTimerRef.current, ms: 500 }; //NOTE:無駄な検索防止に一定時間検索待機
    setEndMonth(value);
  }, []);

  return (
    <>
      <Stack direction="row" spacing={5} alignItems="center" sx={{ mb: 3 }}>
        <PageTitle title="応募一覧" data-e2e="product-title" />
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography sx={{ mr: 1 }}>期間</Typography>
          <MonthSelect
            value={startMonth}
            startMonth={format(subMonths(new Date(), 36), 'yyyy/M')}
            monthCount={72}
            isEmpty
            onChange={handleStartDateChange}
          />
          <Typography>〜</Typography>
          <MonthSelect
            value={endMonth}
            startMonth={format(subMonths(new Date(), 36), 'yyyy/M')}
            monthCount={72}
            isEmpty
            onChange={handleEndDateChange}
          />
        </Stack>
      </Stack>
      <Card>
        <Stack spacing={2}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <TabsComponent
                value={tabIndex}
                acceptedCount={acceptedCount}
                pendingCount={pendingCount}
                processingCount={processingCount}
                visibleUnderLine
                onChange={(event, newValue) => setTabIndex(newValue)}
              />
              {tabIndex === 0 && (
                <Stack direction="row" spacing={1} mx={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={selectedIDs.length < 1}
                    onClick={handleClickAcceptedButton}
                    sx={{ width: '120px' }}
                  >
                    一括採用
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={selectedIDs.length < 1}
                    onClick={handleClickCanceledButton}
                    sx={{ color: theme.palette.error.main, width: '120px' }}
                  >
                    一括不採用
                  </Button>
                </Stack>
              )}
            </Stack>
            <Divider />
          </Box>
          <Box px={2}>
            <SearchTextBox value={keywordFilter} onSubmit={setKeywordFilter} />
          </Box>
          <Box>
            <CustomTabPanel value={tabIndex} index={0}>
              <OrderTableComponent
                orders={pendingOrders}
                selectionIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                rowCount={pendingCount}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                loading={isLoadingOrder}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={1}>
              <OrderTableComponent
                orders={acceptedOrders}
                rowCount={acceptedCount}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                loading={isLoadingOrder}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={2}>
              <OrderTableComponent
                orders={processingOrders}
                rowCount={processingCount}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                loading={isLoadingOrder}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={3}>
              <OrderTableComponent
                orders={allOrders}
                rowCount={allCount}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                loading={isLoadingOrder}
                tab="all"
              />
            </CustomTabPanel>
          </Box>
        </Stack>
      </Card>
      <ConfirmDialog
        title={`${selectedName}を採用しますか？`}
        okButtonText="採用する"
        okButtonLoading={isLoadingDialog}
        open={isOpenAcceptedDialog}
        onClose={handleCloseAcceptedDialog}
      >
        <Stack width="24rem" spacing={2}>
          <Typography>
            「採用する」を押すと、{selectedName}に採用通知が送られます。
          </Typography>
          <Typography color={theme.palette.grey[500]}>
            ※採用後のキャンセルはご遠慮ください。
            <br />
            採用後に、クリニック側からキャンセルする場合、休業補償のリスク・責任が発生します。
          </Typography>
          <FormControlLabel
            sx={{ width: 'fit-content' }}
            control={
              <Checkbox
                checked={isProductArchived}
                onChange={handleChangeProductArchived}
              />
            }
            label="対象の求人については応募を締め切る"
          />
        </Stack>
      </ConfirmDialog>
      <CancelDialog
        orders={selectedOrders}
        open={isOpenCanceledDialog}
        onClose={() => setIsOpenCanceledDialog(false)}
        onSubmit={handleSubmitCancelDialog}
      />
    </>
  );
}
