import { Box, Card, CardContent, Container } from '@mui/material';
import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';

import { createOrganization } from '@app/adapter/organization-service';
import { updateUser } from '@app/adapter/user-service';
import {
  CombinedFormData,
  InReview,
} from '@app/components/Registration/Confirmation/InReview';
import { OrganizationForm } from '@app/components/Registration/Confirmation/OrganizationForm';
import { InquiryFormLink } from '@app/components/Shared/InquiryFormLink';
import {
  loggedInUserState,
  snackbarOpenState,
  snackbarTextState,
  snackbarSeverityState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { organization } from '@app/domain/organization';
import { User } from '@app/types/user';
import { urlToBase64 } from '@app/utils/base64';
import { isError } from '@app/utils/error';
import { UserInfoForm } from '@app/views/UserInfoForm';

/** blocks-5f90 */
type RegistrationStep =
  | 'userInformationForm'
  | 'organizationForm'
  | 'beforeReview'
  | 'inReview'
  | 'confirmed';

export function RegistrationConfirmation(): ReactElement {
  const navigate = useNavigate();
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);
  const setOrganization = useSetRecoilState(organization);
  const org = useRecoilValue(organization);
  useEffect(() => {
    if (_.includes(['waiting_for_review', 'in_review'], org.auditStatus)) {
      setStep('inReview');
    }
  }, [org.auditStatus]);

  const [step, setStep] = useState<RegistrationStep>('userInformationForm');
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarSeverityState = useSetRecoilState(snackbarSeverityState);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSubmitUserInfoForm = useCallback(() => {
    setStep('organizationForm');
    scrollToTop();
  }, []);

  const handleSubmitOrganizationForm = useCallback(() => {
    setStep('inReview');
    scrollToTop();
  }, []);

  const handleSubmitRegistrationForm = useCallback(
    async (formData: CombinedFormData) => {
      const {
        organizationData: {
          name,
          postalCode,
          addressLine1,
          addressLine2,
          addressLine3,
          phoneNumber,
          description,
          customFields: organizationCustomFields,
          iconImageUri,
          logoImageUri,
          certificateImageUri,
        },
        userData: {
          customFields: {
            birthday,
            familyName,
            familyNameKana,
            firstName,
            firstNameKana,
            gender,
            genderSelf,
          },
        },
      } = formData;

      try {
        const certificateImageData = await urlToBase64(certificateImageUri);
        // mainImagesが空の場合、デフォルトの画像をセット
        const iconImageData = await urlToBase64(
          iconImageUri || process.env.PUBLIC_URL + '/clinic-sample-image.png'
        );
        const logoImageData = await urlToBase64(
          logoImageUri || process.env.PUBLIC_URL + '/clinic-sample-image.png'
        );
        const orgResult = await createOrganization({
          addressLine1,
          addressLine2,
          addressLine3,
          certificateImageData,
          customFields: {
            ...organizationCustomFields,
            ownerUserId: userAuthInfoState.id,
          },
          description,
          iconImageData,
          logoImageData,
          name,
          ownerId: userAuthInfoState.id,
          phoneNumber,
          postalCode: `${postalCode}`,
        });

        await updateUser(
          userAuthInfoState.id,
          birthday,
          familyName,
          familyNameKana,
          firstName,
          firstNameKana,
          gender,
          genderSelf,
          userAuthInfoState.accessToken
        );

        setSnackbarText('医療機関情報の登録が完了しました');
        setSnackbarSeverityState('success');
        setSnackbarOpen(true);
        setOrganization(orgResult.data);
        if (loggedInUser) {
          setLoggedInUser({
            ...loggedInUser,
            customFields: {
              familyName,
              firstName,
            } as User['customFields'],
          });
        }
        navigate('/');
      } catch (error) {
        if (isError(error)) {
          setSnackbarText(
            `医療機関情報の登録が出来ませんでした, ${error.message}`
          );
        } else {
          setSnackbarText(`医療機関情報の登録が出来ませんでした`);
        }
        setSnackbarOpen(true);
      }
    },
    [
      navigate,
      loggedInUser,
      setLoggedInUser,
      setSnackbarOpen,
      setSnackbarSeverityState,
      setSnackbarText,
      setOrganization,
      userAuthInfoState.id,
      userAuthInfoState.accessToken,
    ]
  );
  const handleBackInReview = () => {
    setStep('userInformationForm');
  };

  return (
    <Box>
      <Container sx={{ maxWidth: 'unset !important', width: 630 }}>
        <Card sx={{ px: 9, py: 7 }}>
          <CardContent sx={{ p: '0 !important' }}>
            {step === 'userInformationForm' ? (
              <UserInfoForm onSubmit={handleSubmitUserInfoForm} />
            ) : undefined}
            {step === 'organizationForm' ? (
              <OrganizationForm onSubmit={handleSubmitOrganizationForm} />
            ) : undefined}
            {step === 'inReview' ? (
              <InReview
                onSubmit={handleSubmitRegistrationForm}
                onBack={handleBackInReview}
              />
            ) : undefined}
          </CardContent>
          {/* 問い合わせ */}
          <InquiryFormLink />
        </Card>
      </Container>
    </Box>
  );
}
