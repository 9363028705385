import { Typography, TypographyProps } from '@mui/material';

interface PageTitleProps extends TypographyProps {
  'data-e2e'?: string;
  title: string;
}
export function PageTitle(props: PageTitleProps) {
  return (
    <Typography
      variant="h5"
      component="div"
      sx={{ height: '100%', lineHeight: '38px', mb: 0 }}
      data-e2e={props['data-e2e'] || ''}
    >
      {props.title}
    </Typography>
  );
}
